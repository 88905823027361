import { withPasswordProtect } from '@storyofams/next-password-protect';
import i18next from 'i18next';
import Head from 'next/head';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { I18nextProvider } from 'react-i18next';

import '@Assets/styles/styles.scss';

import { CookieDialog } from '@Components/layout';
import { initAuth } from '@Utils/FirebaseUtils';
import { i18NextInitializer } from '@Utils/TranslationUtils';

i18NextInitializer('nl-NL');
initAuth();

function MyApp({ Component, pageProps }: any) {
  const [visible, setVisible] = useState(false);
  const [consent] = useCookies(['CookieConsent']);

  useEffect(() => {
    if (consent.CookieConsent) {
      const dataLayer = Object.keys(consent.CookieConsent).reduce(
        (dataLayer, key) =>
          `${dataLayer}window.dataLayer.push({event:"${key}",${key}:${consent.CookieConsent[key]}});`,
        '',
      );

      const HTML = `window.dataLayer = window.dataLayer||[];${dataLayer}`;

      const script = document.createElement('script');
      script.innerHTML = HTML;

      document.getElementsByTagName('body')[0].appendChild(script);
    } else {
      setVisible(true);
    }
  }, [consent]);

  return (
    <I18nextProvider i18n={i18next}>
      <Head>
        <title>Content Amersfoort - Hét platform van Amersfoort!</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no , maximum-scale=1"
        />
      </Head>

      <Component {...pageProps} />

      {visible && !consent.CookieConsent && <CookieDialog />}
    </I18nextProvider>
  );
}

export default process.env.PASSWORD_PROTECT
  ? withPasswordProtect(MyApp, {
      loginApiUrl: '/api/protection-login',
    })
  : MyApp;
