import { getFirebaseAdmin, init } from 'next-firebase-auth';

export const initAuth = () => {
  init({
    authPageURL: '/inloggen',
    appPageURL: '/',
    loginAPIEndpoint: '/api/login',
    logoutAPIEndpoint: '/api/logout',
    firebaseAdminInitConfig: {
      credential: {
        projectId: 'content-amersfoort',
        clientEmail: 'firebase-adminsdk-xngle@content-amersfoort.iam.gserviceaccount.com',
        privateKey: process.env.FIREBASE_PRIVATE_KEY || '',
      },
      databaseURL: 'https://content-amersfoort-default-rtdb.firebaseio.com/',
    },
    firebaseClientInitConfig: {
      apiKey: 'AIzaSyD9Bg8GleJ1wqmxY2OlLxtjGKWTXjc3Ckw',
      authDomain: 'content-amersfoort.firebaseapp.com',
      databaseURL: 'https://content-amersfoort-default-rtdb.firebaseio.com/',
      projectId: 'content-amersfoort',
    },
    cookies: {
      name: 'content-amersfoort',
      keys: [process.env.FIREBASE_COOKIES_SECRET, process.env.FIREBASE_COOKIES_SECRET_PREV],
      httpOnly: true,
      maxAge: 12 * 60 * 60 * 24 * 1000, // twelve days
      overwrite: true,
      path: '/',
      sameSite: 'strict',
      secure: true,
      signed: true,
    },
  });
};

export const emailDoesExist = async (email: string) => {
  const firebase = getFirebaseAdmin();

  try {
    await firebase.auth().getUserByEmail(email);

    return true;
  } catch {
    return false;
  }
};

export const setUserAsPaid = async (email: string) => {
  const firebase = getFirebaseAdmin();

  const user = await firebase.auth().getUserByEmail(email);

  await firebase.auth().setCustomUserClaims(user.uid, {
    ...user.customClaims,
    paid_at: new Date().toISOString(),
  });

  return true;
};

export const userHasPaid = async (email: string) => {
  try {
    const firebase = getFirebaseAdmin();
    const user = await firebase.auth().getUserByEmail(email);
    return !!user.customClaims?.paid_at;
  } catch {
    return false;
  }
};
